export const lOGIN_API = "login";
export const ME_API = "user/me";

export const COMPANY = "companies";

export const MEMBER = "member";
export const MEMBER_LIST = "members";
export const MEMBER_VERIFICATION = "membersbvn";
export const MEMBER_RESEND_LINK = "resend-verification-email"
export const MEMBER_UPDATE_VERIFICATION = "memberupdateverification/";
export const CHECK_TOKEN_VALIDITY = "check-token-validity"

export const CHECK_UPDATED_EMAIL_AVAILABILITY = "check-email-availability";
export const VERIFY_OTP = "verify-otp";
export const SEND_OTP = "send-otp";

export const CHANGE_PASSWORD = "change-password";
export const CHANGE_PASSWORD_COMPANY = "change-password-company";
export const RESET_PASSWORD = "user/reset-password";
export const FORGOT_PASSWORD = "user/forgot-password";

export const COUNTRIES = "countries";
export const STATES = "states/";
export const CITIES = "cities/";
